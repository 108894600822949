@charset "UTF-8";

// Default.scss
// Include parts of Bootstrap
//Fonts
@import './fonts/Roboto/Basic';

// Bootstrap scss files
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';

@import 'basic';
@import 'common';

// Import custom css
@import 'default';
@import 'custom';
