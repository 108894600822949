@charset "utf-8";
// Override bootstrap variables
$font-family-sans-serif: 'Roboto', 微軟雅黑體, 'Microsoft JhengHei', 微軟正黑體, Arial, sans-serif;
// $body-bg: #fff;
$body-color: #1a1a1a;

//Dev mode: test=triggering test style;
$devMode: 'test';
$devMode: 'default';

//Common Break Points
$max-w-tb: 991px;
$max-w-mb: 767px;

$min-w-dt: 992px;
$mim-w-tb: 768px;

//Fonts
$fontTitleTC: 'Roboto', 微軟雅黑體, 'Microsoft JhengHei', 微軟正黑體, Arial, sans-serif;
$fontTC: 'Roboto', 微軟雅黑體, 'Microsoft JhengHei', 微軟正黑體, Arial, sans-serif;
$fontTitleSC: 'Roboto', 微軟雅黑體, 'Microsoft JhengHei', 微軟正黑體, Arial, sans-serif;
$fontSC: 'Roboto', 微軟雅黑體, 'Microsoft JhengHei', 微軟正黑體, Arial, sans-serif;
$fontTitleEN: 'Roboto', 微軟雅黑體, 'Microsoft JhengHei', 微軟正黑體, Arial, sans-serif;
$fontEN: 'Roboto', 微軟雅黑體, 'Microsoft JhengHei', 微軟正黑體, Arial, sans-serif;

//Image Path
$img-folder: '../../assets/images';
@function imgurl($path) {
  @return url($img-folder + '/' + unquote($path));
}

// common component e.g. btn, nav, pagination, list, card
$colorDefaultBg: $colorDefault;
$colorDefaultBorder: $colorDefault;
$colorDefaultTxt: $colorDefault;
$colorHightLight: $colorLightDefault;

// main bg
$colorMainContentDefaultBg: transparent;

// Intro
$spaceIntroContainer: 0 20px 100px;
$colorIntroTitleBg1: $colorDefault;
$colorIntroTitleBg2: $colorLightDefault;
$colorIntroTitleTxt: $colorDefault;

// jumbotron
$colorJumbotronBg1: $colorDefault;
$colorJumbotronBg2: $colorLightDefault;
$colorJumbotronTxt1: $colorDefault;
$colorJumbotronTxt2: $colorLightDefault;
$colorJumbotronBorder: $colorDefault;

// speakers list
$colorBarBg1: $colorDefault;
$colorBarBg2: $colorHightLight;
$sizeListItemTitleTxt: 1.25em;
$spaceListItem: 1em;

// programme table
$colorTableBg1: $colorDefault;
$colorTableBg2: #eaebeb;
$colorTableBg3: #e6f6f8;
$colorTableBg4: #ddfbee;
$colorTableTxt1: #ffffff;
$spaceTable: 0.5em;
$widthTableLeftTitle: 11em;
$sizeTableTopTitleTxt: 1.2em;
$btnActiveBorderColor: #ababab;

// header
$headerHeight: 160px;
$headerHeightSmall: 75px;
$headerHeightSm: 80px;
$headerHeightTb: 70px;
$headerHeightMb: 60px;

//Common layout e.g. content
$gridMaxWidth: 1380px;
$gridPercent: 94%;
$gridPercentS: 90%;

// globel e.g <p> <a> ...
$colorTxt: $colorBlack;
$cmSpacing: 20px;
$cmSpacingTb: $cmSpacing;
