@import "variables";
@import "mixins";
/* BEGIN Light */

@font-face {
  font-family: Roboto;
  @include fontdef-woff($FontPath, $FontName, $FontVersion, "Light");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  @include fontdef-woff($FontPath, $FontName, $FontVersion, "Light");
  font-weight: light;
  font-style: normal;
}

/* END Light */
/* BEGIN Regular */
@font-face {
  font-family: Roboto;
  @include fontdef-woff($FontPath, $FontName, $FontVersion, "Regular");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  @include fontdef-woff($FontPath, $FontName, $FontVersion, "Regular");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  @include fontdef-woff($FontPath, $FontName, $FontVersion, "Regular");
  font-weight: normal;
  font-style: normal;
}

/* END Regular */
/* BEGIN Black */

@font-face {
  font-family: Roboto;
  @include fontdef-woff($FontPath, $FontName, $FontVersion, "Black");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  @include fontdef-woff($FontPath, $FontName, $FontVersion, "Black");
  font-weight: bold;
  font-style: normal;
}

/* END Black */
