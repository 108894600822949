@charset "utf-8";
//Prefixer (Prefixes used based on http://shouldiprefix.com/)
@mixin transition($transition...) {
  transition: $transition;
}

@mixin transition-property($property...) {
  transition-property: $property;
}

@mixin transition-duration($duration...) {
  transition-property: $duration;
}

@mixin transition-timing-function($timing...) {
  transition-timing-function: $timing;
}

@mixin transition-delay($delay...) {
  transition-delay: $delay;
}

@mixin translate3d-reset() {
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

//transform
@mixin transform($args) {
  -webkit-transform: $args;
  -ms-transform: $args;
  transform: $args;
}

@mixin transform-origin($val...) {
  -webkit-transform-origin: $val;
  -ms-transform-origin: $val;
  transform-origin: $val;
}

@mixin backface-visibility-hidden() {
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@mixin perspective-reset() {
  -moz-perspective: 1000;
  -webkit-perspective: 1000;
  perspective: 1000;
}

//animation
@mixin animation($args) {
  -webkit-animation: $args;
  animation: $args;
  @include animation-fill-mode(forwards);
  will-change: transform;
}

@mixin animation-pure($args) {
  -webkit-animation: $args;
  animation: $args;
  @include animation-fill-mode(forwards);
}

@mixin animation-duration($val) {
  -webkit-animation-duration: $val;
  animation-duration: $val;
}

@mixin animation-delay($val) {
  -webkit-animation-delay: $val;
  animation-delay: $val;
}

@mixin animate($animate...) {
  $max: length($animate);
  $animations: '';
  @for $i from 1 through $max {
    $animations: #{$animations + nth($animate, $i)};
    @if $i < $max {
      $animations: #{$animations + ', '};
    }
  }
  -webkit-animation: $animations;
  animation: $animations;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animation-fill-mode($mode) {
  -webkit-animation-fill-mode: $mode;
  animation-fill-mode: $mode;
}

@mixin border-radius($val) {
  border-radius: $val;
}

@mixin box-sizing($val) {
  box-sizing: $val;
}

// UI
@mixin image-fill-container($flexmode: inline) {
  @if $flexmode==inline {
    display: inline-flex;
  } @else {
    display: flex;
  }
  justify-content: center;
  align-items: center;
  & > a {
    @if $flexmode==inline {
      display: inline-flex;
    } @else {
      display: flex;
    }
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    & > img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  & > img {
    max-width: 100%;
    max-height: 100%;
  }
  .item-child-item--cols .child-item-container & > img {
    max-width: 100%;
    max-height: 100%;
  }
}

//Maths
// Ref: _decimal.scss | gist.github.com/terkel/4373420
// Round a number to specified digits.
//
// @param  {Number} $number A number to round
// @param  {Number} [$digits:0] Digits to output
// @param  {String} [$mode:round] (round|ceil|floor) How to round a number
// @return {Number} A rounded number
// @example
//     decimal-round(0.333)    => 0
//     decimal-round(0.333, 1) => 0.3
//     decimal-round(0.333, 2) => 0.33
//     decimal-round(0.666)    => 1
//     decimal-round(0.666, 1) => 0.7
//     decimal-round(0.666, 2) => 0.67
//
@function decimal-round($number, $digits: 0, $mode: round) {
  $n: 1; // $number must be a number
  @if type-of($number) !=number {
    @warn '#{ $number } is not a number.';
    @return $number;
  } // $digits must be a unitless number
  @if type-of($digits) !=number {
    @warn '#{ $digits } is not a number.';
    @return $number;
  } @else if not unitless($digits) {
    @warn '#{ $digits } has a unit.';
    @return $number;
  }
  @for $i from 1 through $digits {
    $n: $n * 10;
  }
  @if $mode==round {
    @return round($number * $n) / $n;
  } @else if $mode==ceil {
    @return ceil($number * $n) / $n;
  } @else if $mode==floor {
    @return floor($number * $n) / $n;
  } @else {
    @warn '#{ $mode } is undefined keyword.';
    @return $number;
  }
}

// Ceil a number to specified digits.
//
// @param  {Number} $number A number to round
// @param  {Number} [$digits:0] Digits to output
// @return {Number} A ceiled number
// @example
//     decimal-ceil(0.333)    => 1
//     decimal-ceil(0.333, 1) => 0.4
//     decimal-ceil(0.333, 2) => 0.34
//     decimal-ceil(0.666)    => 1
//     decimal-ceil(0.666, 1) => 0.7
//     decimal-ceil(0.666, 2) => 0.67
//
@function decimal-ceil($number, $digits: 0) {
  @return decimal-round($number, $digits, ceil);
}

// Floor a number to specified digits.
//
// @param  {Number} $number A number to round
// @param  {Number} [$digits:0] Digits to output
// @return {Number} A floored number
// @example
//     decimal-floor(0.333)    => 0
//     decimal-floor(0.333, 1) => 0.3
//     decimal-floor(0.333, 2) => 0.33
//     decimal-floor(0.666)    => 0
//     decimal-floor(0.666, 1) => 0.6
//     decimal-floor(0.666, 2) => 0.66
//
@function decimal-floor($number, $digits: 0) {
  @return decimal-round($number, $digits, floor);
}

//Simplifiers
@mixin mediaQuery($val) {
  @media screen and (max-width: $val) {
    #currentMedia {
      max-width: $val;
    }
  }
}

@mixin fontSize($size) {
  //mixins for font-size conversion
  font-size: $size * 1px;
}

@mixin testStyle() {
  @if $devMode== 'test' {
    /*Test Style*/
    @content;
    /*End Style*/
  }
}

@mixin rotate($val) {
  @include transform($val);
}

@mixin placeholder-style() {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    @content;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin user-select($val) {
  -webkit-touch-callout: $val;
  /* iOS Safari */
  -webkit-user-select: $val;
  /* Safari */
  -khtml-user-select: $val;
  /* Konqueror HTML */
  -moz-user-select: $val;
  /* Firefox */
  -ms-user-select: $val;
  /* Internet Explorer/Edge */
  user-select: $val;
  /* Non-prefixed version, currently*/
}

@mixin getGridClass($name, $endVal, $mode) {
  //mode 1: selector prefix = "&"
  @if $mode==1 {
    @for $i from 1 through $endVal {
      $result: $i/$endVal * 100%;
      &.#{$name}#{$i}-#{$endVal} {
        width: $result;
      }
    }
  } @else {
    @for $i from 1 through $endVal {
      $result: $i/$endVal * 100%;
      .#{$name}#{$i}-#{$endVal} {
        width: $result;
      }
    }
  }
}

@mixin replaceSelector($oldSelector, $newSelector) {
  @at-root #{selector-replace(&, $oldSelector, $newSelector)} {
    @content;
  }
}

@mixin platformSelector($platform) {
  @include replaceSelector('body', $platform) {
    @content;
  }
}

@mixin overlayStyle($opacity, $zid) {
  width: 100%;
  height: 100%;
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, $opacity);
  z-index: $zid;
}

@mixin overlayAfter($opacity, $zid) {
  &:after {
    @include overlayStyle($opacity, $zid);
  }
}

@mixin overlayBefore($opacity, $zid) {
  &:before {
    @include overlayStyle($opacity, $zid);
  }
}

@mixin clearfix() {
  content: '';
  display: table;
  width: 100%;
  height: 0;
  clear: both;
}

@mixin clearAfter() {
  &:after {
    @include clearfix();
  }
}

@mixin clearBefore() {
  &:before {
    @include clearfix();
  }
}

@mixin ghostElem() {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 100%;
}

@mixin ghostBefore() {
  &:before {
    @include ghostElem();
  }
}

@mixin ghostAfter() {
  &:after {
    @include ghostElem();
  }
}

@mixin btnIconToRight() {
  &:after {
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -9px;
  }
}

@mixin blurFilter($scale) {
  -webkit-filter: blur($scale);
  -moz-filter: blur($scale);
  -o-filter: blur($scale);
  -ms-filter: blur($scale);
  filter: blur($scale);
}

@mixin menuBlur() {
  @include transition(filter 0.4s);
  $scale: 4px;
  @media screen and (max-width: 991px) {
    .mob-menu-open & {
      @include blurFilter($scale);
    }
  }
  .search-panel-open & {
    @include blurFilter($scale);
  }
}

@mixin centerX() {
  left: 50%;
  @include transform(translateX(-50%));
}

@mixin centerY() {
  top: 50%;
  @include transform(translateY(-50%));
}

@mixin centerXY() {
  left: 50%;
  top: 50%;
  @include transform(translate(-50%, -50%));
}

@mixin aniFadeInBasic($time, $delay) {
  opacity: 0;
  .viewing & {
    @include animation($time fade-in-basic);
    @include animation-delay($delay);
  }
}

@mixin aniFadeInFromLeft($time, $delay) {
  opacity: 0;
  .viewing & {
    @include animation($time fade-in-from-left);
    @include animation-delay($delay);
  }
}

@mixin aniFadeInFromRight($time, $delay) {
  opacity: 0;
  .viewing & {
    @include animation($time fade-in-from-right);
    @include animation-delay($delay);
  }
}

@mixin aniFadeInFromTop($time, $delay) {
  opacity: 0;
  .viewing & {
    @include animation($time fade-in-from-top);
    @include animation-delay($delay);
  }
}

@mixin aniFadeInFromBottom($time, $delay) {
  opacity: 0;
  .viewing & {
    @include animation($time fade-in-from-bottom);
    @include animation-delay($delay);
  }
}

$loops: 0;
@function approximateSq($num, $approx) {
  $root: (($num / $approx) + $approx) / 2;
  @return $root;
}

@function sqrt($num) {
  $root: 0;
  $testRoot: 0;
  $upperBounds: round($num / 2) + 1; //never need over half the main number. Add one just to be sure!
  $loops: $upperBounds;
  @for $test from 2 through $upperBounds {
    $sq: $test * $test;
    @if $sq <=$num {
      $testRoot: $test;
    }
  }
  $root: (approximateSq($num, $testRoot));
  @return $root;
}

@mixin onlyEN() {
  :lang(en) & {
    @content;
  }
}

@mixin onlyTC() {
  :lang(zh-hk) & {
    @content;
  }
}

@mixin onlySC() {
  :lang(zh-cn) & {
    @content;
  }
}

@mixin onlyCN() {
  :lang(zh-hk) &,
  :lang(zh-cn) & {
    @content;
  }
}

@mixin mediaModifier() {
  &--md-dt {
    @media screen and (min-width: 992px) {
      @content;
    }
  }
  &--md-tb {
    @media screen and (max-width: 991px) {
      @content;
    }
  }
  &--md-mb {
    @media screen and (max-width: 640px) {
      @content;
    }
  }
}

@mixin access() {
  position: absolute;
  left: 0px;
  top: -500px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

//Calculate, Converter, etc.
@mixin hElem() {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @content;
  }
}

@mixin getSizeByScale($oriW, $oriH, $scale) {
  width: $oriW * $scale;
  height: $oriW * $scale;
}

@mixin getFullPageHeaderOffset($property_name: margin-top) {
  #{$property_name}: $headerHeight + 40;
  @media screen and (max-width: 1480px) {
    #{$property_name}: $headerHeightSm + 40;
  }
  @media screen and (max-width: 991px) {
    #{$property_name}: $headerHeightTb + 40;
  }
  @media screen and (max-width: 767px) {
    #{$property_name}: $headerHeightMb + 20;
  }
}

@mixin getWrapperWidth() {
  width: $gridPercent;
  @media screen and (max-width: 767px) {
    $gridPercentS: 90%;
  }
}
@mixin fullyCover() {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@mixin mobile {
  @media screen and (max-width: 767px) {
    @content;
  }
}
@mixin non-mobile {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 991px) {
    @content;
  }
}
@mixin non-tablet {
  @media screen and (min-width: 992px) {
    @content;
  }
}
